import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import Fonts from './Fonts';
import './reset.css';
import './styles.css';

if (typeof window !== 'undefined') {
  const WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: ['Khula:300', 'sans-serif'],
    }
  });
}

const theme = {
  black: '#191919',
  white: '#FFFFFF',
  grey: '#F2F2F2',
  maxWidth: '1200px'
};

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const Inner = styled.main`
  flex-grow: 1;
  width: 100%;
`;

const Layout = ({ children, home }) => {
  useEffect(() => Fonts(), []);
  return (
    <ThemeProvider theme={theme}>
      <StyledPage>
        <Inner>
          {children}
        </Inner>
      </StyledPage>
    </ThemeProvider>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
