import React from "react";
import { Shaders, Node, GLSL } from "gl-react";

const shaders = Shaders.create({
  backgroundCanvas: {
    frag: GLSL`
    precision mediump float;
    highp float rand(vec2 co)
    {
      highp float a = 12.9898;
      highp float b = 78.233;
      highp float c = 43758.5453;
      highp float dt= dot(co.xy ,vec2(a,b));
      highp float sn= mod(dt,3.14);
      return fract(sin(sn) * c);
    }
    uniform float u_blockSize;
    uniform float u_time;
    uniform float u_variance;
    void main()
    {
      vec3 col = vec3(0.208);
      float fragCoordBlocks = rand(floor(gl_FragCoord.xy/u_blockSize))*(3.14159265359*2.0);
      float variance = sin(u_time*u_variance+fragCoordBlocks);
      col = col + ((variance-.5)*0.7);
      gl_FragColor = vec4(col,1.0);
    }`
  }
});

class CanvasPresentation extends React.Component {
  render() {
    const { u_time } = this.props;
    let u_blockSize;
    if (window.devicePixelRatio === 3) {
      u_blockSize = 240;
    } else if (window.devicePixelRatio === 2) {
      u_blockSize = 120;
    } else {
      u_blockSize = 60;
    }
    const u_variance = 2.217;
    return <Node shader={shaders.backgroundCanvas} uniforms={{ u_blockSize, u_time, u_variance }} />
  }
}

export default CanvasPresentation;
