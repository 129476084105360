import styled, { keyframes } from 'styled-components';

const companyColor = '#ccff00';
const backgroundColor = '#000000';
const openPage = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const HomeStyles = styled.article`
  background: ${backgroundColor};
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 35px;
  
  .pane {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: ${openPage} 300ms linear;
  }
 
  .pattern {
    align-items: center;
    max-width: 700px;
  }
  
  .green {
    &::selection {
      color: #009A49;
    }
  }
  
  .white {
    &::selection {
      color: #FFFFFF;
    }
  }
  
  .orange {
    &::selection {
      color: #FF7900;
    }
  }
  
  h1, h3, p, a, span {
    &::selection {
      background: none;
    }
  }
  h1 {
    color: ${companyColor};
    display: inline;
  }
  h3 {
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-size: 14px;
    &:after {
      content: '';
      height: 1px;
      width: 16px;
      background: ${companyColor};
      display: block;
      margin-top: 8px;
    }
  }
  p {
    display: inline;
  }
  section {
    max-width: 744px;
  }
  .detail {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 50px;
  }
  a {
    color: #ffffff;
    font-size: 32px;
    &:hover {
      color: ${companyColor};
    }
  }
  .grid {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 480px;
    height: 300px;
    overflow: hidden;
  }
  
  .overlay {
    background: ${companyColor};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    mix-blend-mode: overlay;
    opacity: 0.99;
  }
  
  .canvas {
    position: absolute;
    left: 0;
    right: 0;
    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 2000ms;
    }
  }
  
  @media (max-width: 1000px) {
    justify-content: space-evenly;
    flex-direction: column;
  
    .pane {
      width: 100%;
    }
    
    .pattern {
      align-items: flex-start;
    }
  }
  
  @media (max-width: 575px) {
    .pattern {
      display: none;
    }
    .detail {
      font-size: 36px;
    }
    
    a {
      font-size: 20px;
    }
    
    h3 {
    font-size: 12px;
    }
  }
`;

export { HomeStyles };
