import React, { useCallback, useEffect, useState } from "react"
import { HomeStyles } from './styles';
import AnimatedCanvas from '../AnimatedCanvas';

const Home = () => {
  const [ elapsed, setElapsed ] = useState(0);
  const [ startTime ] = useState(Date.now());
  const [ renderTile, setRenderTile ] = useState(false);

  const tick = useCallback(() => {
      setElapsed(new Date() - startTime);
  }, [startTime]);

  useEffect(() => {
    let timer = setInterval(tick, 10);
    return () => clearInterval(timer);
  }, [tick]);

  useEffect(() => {
    setRenderTile(true);
  }, []);

  return (
    <HomeStyles>
      {renderTile && (
        <>
          <div className="pane">
            <section className="detail">
              <h1><span className="green">Pic</span><span className="white">tei</span><span className="orange">lin</span></h1>
              <p> is a software development consultancy. We transform ideas into experiences.</p>
            </section>
            <section className="contact">
              <h3>CONTACT US</h3>
              <a href="mailto:hello@picteilin.io">
                hello@picteilin.io
              </a>
            </section>
          </div>
          <div className="pane pattern">
            <div className="grid">
              <div className="canvas">
                <AnimatedCanvas elapsed={elapsed} />
              </div>
              <div className="overlay"/>
            </div>
          </div>
        </>
      )}
    </HomeStyles>
  );
}

export default Home;
