import React from 'react';
import Layout from '../components/Layout';
import Home from '../components/Home';
import SEO from '../components/Seo';

const HomePage = () => (
  <Layout home>
    <SEO />
    <Home />
  </Layout>
);

export default HomePage;
