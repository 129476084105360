const FontFaceObserver = require('fontfaceobserver');

const Fonts = () => {
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Khula:wght@300&display=swap';
  link.rel = 'stylesheet';

  document.head.appendChild(link);

  const khula = new FontFaceObserver('Khula');

  khula.load().then(() => {
    document.documentElement.classList.add('khula');
  });
};

export default Fonts;
