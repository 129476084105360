import React, { Component } from 'react';
import { Surface } from "gl-react-dom";
import Canvas from './presentation';

class CanvasContainer extends Component {
  state = {
    windowDimensions: {
      width: 0,
      height: 0,
    },
  };

  updateDimensions = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const ratio = (windowHeight / windowWidth / 5) + 1;
    this.setState(
      {
        windowDimensions: {
          width: window.innerWidth * ratio,
          height: window.innerHeight * 1.2,
        },
      }
    );
  };

  render() {
    const elapsed = Math.round(this.props.elapsed / 100);
    const seconds = (elapsed / 10);
    return (
      <Surface width={480} height={300}>
        <Canvas u_time={seconds} />
      </Surface>
    )
  }

}

export default CanvasContainer;
